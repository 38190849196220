import React, { Component } from "react";
import swal from "sweetalert";
import axios from "axios";

import './sidebar.css';

class Sidebar extends Component {


    constructor(props) {
        super(props)

        this.state = {
                currentTime:new Date(),
                activeSection: window.location.href.replace('http://', '').split('/')[1],
                customerId: localStorage.getItem("currentCustomer"),
                customer: {},
                pendingRepairs: [],
                pendingSales: [],
                ut: this.parseJwt()
            }
            // console.log(window.location.href.replace('http://','').split('/'))

        let self = this
        self.fetchCustomer(()=>{
          self.fetchRepairs(()=>{
            self.fetchSales(()=>{

            })
          })

        })
    }
    parseJwt() {
        let token = localStorage.getItem("jwtToken");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
    }

    componentDidUpdate(nextProps, nextState) {
      if(this.props.tick != nextProps.tick){

        console.log("updating sidebar");
        console.log({t:nextProps.tick});
        this.fetchCustomer() 
      }
    }

    fetchSales(callback) {
        let self = this
        if (!self.state.customerId) {
            return
        }

        axios
            .get("/api/sales/customer/" + self.state.customerId + '/pending')
            .then(res => {
                if (res.data) {
                    const pendingSales = res.data

                    self.setState({
                        pendingSales
                    },callback)
                }
                else {

                    self.setState({
                        pendingSales: []
                    },callback)
                }
                // else if (res.data.result === "error") {
                console.log({
                    customer: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });

    }
    fetchRepairs(callback) {
        let self = this
        if (!self.state.customerId) {
            return
        }

        axios
            .get("/api/repairs/customer/" + self.state.customerId + '/pending')
            .then(res => {
                if (res.data) {
                    const pendingRepairs = res.data

                    self.setState({
                        pendingRepairs
                    }, callback)
                }
                else {

                    self.setState({
                        pendingRepairs: []
                    },callback)
                }
                // else if (res.data.result === "error") {
                console.log({
                    customer: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });

    }

    fetchCustomer(callback) {
        let self = this
        if (!self.state.customerId) {
            return
        }

        axios
            .get("/api/customers/view/" + self.state.customerId)
            .then(res => {
                if (res.data) {
                    const customer = res.data

                    self.setState({
                        customer
                    }, callback)
                }
                else {

                    self.setState({
                        customer: {}
                    },callback)
                }
                // else if (res.data.result === "error") {
                console.log({
                    customer: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });

    }


    createPayoutTransaction() {

        let self = this
        let customer = self.state.customerId
        let user = self.state.ut.id
            //{type,title,description,instructions,customer,user,price}

        let shopId = localStorage.currentShop || 'EJR'

        axios.post('/api/customers/payout', {
            customer,
            user,
            shopId
        }).then((data) => {
            console.log(data)

            swal({
              title:"Success!",
              text:'Transaction created successfully',
              type:"success",
              timer:1000
            }).then(value => {
                if(data.data && data.data.transaction){
                  let transaction = data.data.transaction
                  if(transaction._id){
                    window.location='/transaction/'+transaction._id   
                  }
                }
            });

        }).catch((e) => {
            console.log('Creation failed, Error ', e)
                // self.setState({errorMessage:e})
        });
    }

  render() {
    console.log(this.state)
    let self=this

    let profile=[]
    let navProfile = []
    let navActions = []
    if(this.state.customer){
     
      if(this.state.customer.firstName || this.state.customer.lastName){
        let name = [this.state.customer.lastName,this.state.customer.firstName].filter((n)=>{ return n && n.trim().length}).join(', ')
        profile.push(
          <div key="customer_name">{name}</div>
        )
      }

      if(this.state.customer.address1){
        profile.push(
          <div key="customer_add1">{this.state.customer.address1}</div>
        )
      }

      if(this.state.customer.city || this.state.customer.state){
        let csz = [this.state.customer.city,this.state.customer.state].filter((n)=>{ return n && n.trim().length}).join(', ')
        profile.push(
          <div key="customer_csz">{csz}</div>
        )
      }

      if(profile && profile.length){

        navProfile.push(

            <div key='n_profile' className="user-panel mt-3 pb-3 mb-3 d-flex">

              <a href="/customer" className="nav-link">
              {
                // <i className="nav-icon far fa-user" style={{float:'left', lineHeight:'2em', width:'1.4em',fontSize:'2em'}} />
              }
                {profile}
                </a>
            </div>
        )

        let repairCount = 0
        let repairSubtotal = 0

        let saleCount = 0
        let saleSubtotal = 0
        let isTaxable = false

        if(this.state.pendingRepairs && this.state.pendingRepairs.length){
          repairCount = this.state.pendingRepairs.length
          repairSubtotal = 0
          this.state.pendingRepairs.forEach((r)=>{
            repairSubtotal+=r.price
            isTaxable = (isTaxable || r.taxable)
          })
        }

        let repairSummary = null
        if(repairCount>0){
          repairSummary = (

              <li key="sn_repair_summary" className="nav-item">
                <div className="nav-link">
                  <i className="nav-icon fas fa-plus" />
                  <p>
                    Repairs: ${repairSubtotal.toFixed(2)}
                    <span className="badge badge-info right">{repairCount}</span>
                  </p>
                </div>
              </li>
          )
        }


        if(this.state.pendingSales && this.state.pendingSales.length){
          saleCount = this.state.pendingSales.length
          saleSubtotal = 0
          this.state.pendingSales.forEach((r)=>{
            saleSubtotal+=r.price
            isTaxable = (isTaxable || r.taxable)
          })
        }

        let saleSummary = null
        if(saleCount>0){
          saleSummary = (

              <li key="sn_sale_summary" className="nav-item">
                <div className="nav-link">
                  <i className="nav-icon fas fa-plus" />
                  <p>
                    Sales: ${saleSubtotal.toFixed(2)}
                    <span className="badge badge-info right">{saleCount}</span>
                  </p>
                </div>
              </li>
          )
        }






        let payoutAmount=repairSubtotal+saleSubtotal

        if(payoutAmount>=0.01 || payoutAmount<=-0.01){
          let payoutTaxable = null
          if(isTaxable){
            payoutTaxable = <span style={{fontSize:'.5em'}}>&nbsp;(+TAX)</span>
          }


          navProfile.push(
            <div key='n_profile_summary' className="user-panel mt-2">

              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  <li className="nav-item has-treeview menu-open">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-shopping-cart" />
                      <p>
                        Pay Out
                        <span className="badge badge-info right">{repairCount+saleCount}</span>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {repairSummary}
                      {saleSummary}
                      <li className="nav-item">
                        <button type="button" className="btn btn-block btn-success" onClick={()=>{this.createPayoutTransaction()}}>
                          Pay Out ${payoutAmount.toFixed(2)}
                          {payoutTaxable}
                        </button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          )
          navProfile.push(
            <div key='n_profile_summary' className="user-panel mt-3 pb-3 mb-3 d-flex"/>)
        }


        navActions.push(

              <li key='n_action_transactions' className="nav-item">
                <a href="/transactions" className={"nav-link" + ' ' + this.state.activeSection + ' ' + (this.state.activeSection.indexOf('transaction')>=0?' active':'')}>
                  <i className="nav-icon fas fa-receipt" />
                  <p>
                    Transactions
                    {/*<span className="badge badge-info right">6</span>*/}
                  </p>
                </a>
              </li>
        )
        navActions.push(

              <li key='n_action_repairs' className="nav-item">
                <a href="/repairs" className={"nav-link" + ' ' + this.state.activeSection + ' ' + (this.state.activeSection.indexOf('repair')>=0?' active':'')}>
                  <i className="nav-icon fas fa-tools" />
                  <p>
                    Repairs
                    {/*<span className="badge badge-info right">6</span>*/}
                  </p>
                </a>
              </li>
        )
        navActions.push(

              <li key='n_action_sales' className="nav-item">
                <a href="/sales" className={"nav-link" + ' ' + this.state.activeSection + ' ' + (this.state.activeSection.indexOf('sale')>=0?' active':'')}>
                  <i className="nav-icon fas fa-gem" />
                  <p>
                    Sales
                   {/* <span className="badge badge-info right">6</span>*/}
                  </p>
                </a>
              </li>
        )
        navActions.push(

              <li key='n_action_orders' className="nav-item">
                <a href="/orders" className={"nav-link" + ' ' + this.state.activeSection + ' ' + (this.state.activeSection.indexOf('order')>=0?' active':'')}>
                  <i className="nav-icon fas fa-dolly" />
                  <p>
                    Orders
                    {/*<span className="badge badge-info right">6</span>*/}
                  </p>
                </a>
              </li>
        )
        navActions.push(

              <li key='n_action_layaway' className="nav-item">
                <a href="/layaways" className={"nav-link" + ' ' + this.state.activeSection + ' ' + (this.state.activeSection.indexOf('layaway')>=0?' active':'')}>
                  <i className="nav-icon fas fa-clock" />
                  <p>
                    Layaway
                    {/*<span className="badge badge-info right">6</span>*/}
                  </p>
                </a>
              </li>
        )
        navActions.push(

              <li key='n_action_appraisals' className="nav-item">
                <a href="/appraisals" className={"nav-link" + ' ' + this.state.activeSection + ' ' + (this.state.activeSection.indexOf('appraisal')>=0?' active':'')}>
                  <i className="nav-icon fas fa-microscope" />
                  <p>
                    Appraisals
                    {/*<span className="badge badge-info right">6</span>*/}
                  </p>
                </a>
              </li>
        )
        navActions.push(

              <li key='n_action_credits' className="nav-item">
                <a href="/credits" className={"nav-link" + ' ' + this.state.activeSection + ' ' + (this.state.activeSection.indexOf('credits')>=0?' active':'')}>
                  <i className="nav-icon fas fa-wallet" />
                  <p>
                    Credits
                    {/*<span className="badge badge-info right">6</span>*/}
                  </p>
                </a>
              </li>
        )
        // navActions.push(

        //       <li key='n_action_wish' className="nav-item">
        //         <a href="#" className="nav-link">
        //           <i className="nav-icon fas fa-copy" />
        //           <p>
        //             Wishlist
        //             <span className="badge badge-info right">6</span>
        //           </p>
        //         </a>
        //       </li>
        // )
      }
    }

    let currentHeader = (
      <a className="nav-link brand-link" data-toggle="dropdown" href="#">
                <img
                  src="/ejr-logo-gray.png"
                  alt="Elyria Jewelers"
                  className="brand-image img-circle elevation-3"
                  style={{ opacity: ".8" }}
                />
                <span className="brand-text font-weight-light">Elyria Jewelers</span>
            </a>
    )
    if(`${localStorage.currentShop}`.toLowerCase()==='willow'){
      currentHeader = (
        <a className="nav-link brand-link" data-toggle="dropdown" href="#">
                  <img
                    src="/willow-logo-gray.png"
                    alt="Willow Jewelers"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: ".8" }}
                  />
                  <span className="brand-text font-weight-light">Willow Jewelers</span>
              </a>
      )
    }
    else if(`${localStorage.currentShop}`.toLowerCase()==='endless'){
      currentHeader = (
        <a className="nav-link brand-link" data-toggle="dropdown" href="#">
                  <img
                    src="/endless-logo-gray.png"
                    alt="Endless & Forever"
                    className="brand-image img-circle elevation-3"
                    style={{ opacity: ".8" }}
                  />
                  <span className="brand-text font-weight-light">Endless & Forever</span>
              </a>
      )
    }

    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">

        <ul className="navbar-nav ml-auto">
          {/* Messages Dropdown Menu */}
          {/* Notifications Dropdown Menu */}

          <li className="nav-item dropdown">
            {currentHeader}

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">Choose Location</span>
              
              <div className="dropdown-divider" />
              
              <a href="#" className="brand-link" onClick={()=>{
                localStorage.setItem("currentShop", 'ejr');
                self.setState({currentTime:new Date()})
              }}>
                <img
                  src="/ejr-logo-gray.png"
                  alt="Elyria Jewelers"
                  className="brand-image img-circle elevation-3"
                  style={{ opacity: "0" }}
                />
                <span className="brand-text font-weight-light" style={{color:'black'}}>Elyria Jewelers</span>
              </a>

              <div className="dropdown-divider" />
              
              <a href="#" className="brand-link" onClick={()=>{
                localStorage.setItem("currentShop", 'willow');
                self.setState({currentTime:new Date()})
              }}>
                <img
                  src="/willow-logo-gray.png"
                  alt="Willow Jewelers"
                  className="brand-image img-circle elevation-3"
                  style={{ opacity: "0" }}
                />
                <span className="brand-text font-weight-light" style={{color:'black'}}>Willow Jewelers</span>
              </a>

              <div className="dropdown-divider" />
              
              <a href="#" className="brand-link" onClick={()=>{
                localStorage.setItem("currentShop", 'endless');
                self.setState({currentTime:new Date()})
              }}>
                <img
                  src="/endless-logo-gray.png"
                  alt="Endless & Forever"
                  className="brand-image img-circle elevation-3"
                  style={{ opacity: "0" }}
                />
                <span className="brand-text font-weight-light" style={{color:'black'}}>Endless & Forever</span>
              </a>


            </div>
          </li>
        </ul>


        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar user (optional) */}
          {navProfile}
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {navActions}

            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    );
  }
}

export default Sidebar;
