import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './transactions.css';

import Modal from "../../modal";

import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";


const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);



const ELYRIA_TAX_RATE = .065

class Transactions extends Component {

    constructor(props) {
        super(props)
		let currentCustomer = localStorage.getItem("currentCustomer")
        this.state = {
            customerId: currentCustomer,
            transactionId: window.location.href.split('/transaction/')[1].split('/').slice(0)[0],
            transaction: {},
            photos: {},
            payments: [],
            creditDetails: {credits:[],balance:0},
            repairs: [],
            sales: [],
            allFetched: false,
            ut: this.parseJwt(),
            // lineItemTotal:0,
            // taxableTotal:0,
            // taxAmount:0,
            // totalAmount:0,
            // totalPayments:0,
            // balance:0,
            showPaymentHistoryModal:false,
            showPaymentModal:false,
            paymentMethod:'',
            paymentAmount:'',
            maxPaymentAmount:'',
            paymentNote:'',
            selectedPayment:null,
            selectedDate:null
        }
		// if(!currentCustomer){
		// 	window.location = '/customers'
		// 	return
		// }

        this.recalculate()
    }
    // componentWillMount() {
    //     let self=this
	// 	let currentCustomer = localStorage.getItem("currentCustomer")
	// 	if(!currentCustomer){
	// 		window.location = '/customers'
	// 	}
    //     console.log({transaction:'mounted',currentCustomer})
    // }
    parseJwt() {
        let token = localStorage.getItem("jwtToken");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
    }

    fetchPhotos(){
    	let self=this;
    	let refIds = [];

    	console.log({fetchPhotos:self.state})
    	if(self.state.repairs && self.state.repairs.length){
    		self.state.repairs.forEach((r)=>{ 
    			console.log(r._id)
    			refIds.push(r._id)
    		})	
    	}
    	if(self.state.sales && self.state.sales.length){
    		self.state.sales.forEach((s)=>{
    			console.log(s._id)
    			refIds.push(s._id)
    		})
    	}


		axios.post('/api/photos/multi',{refIds}).then( (res) => {

			console.log({multiPhotos:res.data})
                if (res.data) {
                    self.setState({
                    	allFetched:true,
                        photos: res.data
                    })
                }
                else {

                    self.setState({
                    	allFetched:true,
                        photos: []
                    })
                }
	    }).catch((e) => {
	    	console.log('photo fetch failed, Error ',e)
            self.setState({
            	allFetched:true,
                photos: []
            })
			// self.setState({errorMessage:e})
		});
    }

    fetchPayments(){
        let self = this

        axios
            .get("/api/payments/" + self.state.transactionId)
            .then(res => {
                if (res.data) {
                    self.setState({
                        payments: res.data
                    },()=>{
                        self.fetchCredits()
                    })
                }
                else {
                    self.setState({
                        payments: []
                    },()=>{
                        self.fetchCredits()
                    })
                }
            })
            .catch(error => {
                console.log(error);
                self.setState({
                    payments: []
                },()=>{
                    self.fetchCredits()
                })
            });

    }


    fetchCredits(){
        let self = this

        axios
            .get("/api/credits/customer/" + self.state.customerId)
            .then(res => {
                if (res.data) {
                    self.setState({
                        creditDetails: res.data
                    },()=>{
                        self.fetchPhotos()
                    })
                }
                else {
                    self.setState({
                        creditDetails: {credits:[],balance:0}
                    },()=>{
                        self.fetchPhotos()
                    })
                }
            })
            .catch(error => {
                console.log(error);
                self.setState({
                    creditDetails: {credits:[],balance:0}
                },()=>{
                    self.fetchPhotos()
                })
            });

    }

    fetchSales(){
        let self = this


        axios
            .get("/api/sales/" + self.state.transactionId)
            .then(res => {
                if (res.data) {
                    self.setState({
                        sales: res.data
                    },()=>{
                    	self.fetchPayments()
                    })
                }
                else {
                    self.setState({
                        sales: []
                    },self.fetchPayments)
                }
            })
            .catch(error => {
                console.log(error);
                self.setState({
                    sales: []
                },self.fetchPayments)
            });

    }
    fetchRepairs(){
        let self = this


        axios
            .get("/api/repairs/" + self.state.transactionId)
            .then(res => {
                if (res.data) {
                    self.setState({
                        repairs: res.data
                    },()=>{
                    	self.fetchSales()
                    })
                }
                else {
                    self.setState({
                        repairs: []
                    },self.fetchSales)
                }
            })
            .catch(error => {
                console.log(error);
                self.setState({
                    repairs: []
                },self.fetchSales)
            });

    }

    fetch() {
        let self = this

        axios
            .get("/api/transactions/view/" + self.state.transactionId)
            .then(res => {
                if (res.data) {
                    self.setState({
                    	allFetched:false,
                        transaction: res.data
                    },()=>{
                    	self.fetchRepairs()
                    })
                }
                else {
                    self.setState({
                    	allFetched:false,
                        transaction: {}
                    },self.fetchRepairs)
                }
            })
            .catch(error => {
                console.log(error);
                self.setState({
                	allFetched:false,
                    transaction: {}
                },self.fetchRepairs)
            });
    }

    updateField(field, value) {
    	let self = this
        let transaction = this.state.transaction
        transaction[field] = value
        if(field==='closed' && value){
            transaction.closeDate = new Date()

        }
        transaction.shopId = localStorage.currentShop || 'EJR'
        this.setState({
            transaction
        }, self.update)
    }

    update() {
        let self = this
        let transaction = this.state.transaction

        transaction.shopId = localStorage.currentShop || 'EJR'

        axios.put('/api/transactions/' + transaction._id, {
            transaction
        }).then((data) => {
            console.log(`transaction updated successfully`);

            self.fetch()
        }).catch((e) => {
            console.log('update failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });
    }


    createPayment(){
    	let self=this

    	self.setState({
    		showPaymentModal:true,
    		paymentMethod:'',
            paymentAmount:(self.state.transaction.balance||0).toFixed(2),
            maxPaymentAmount:(self.state.transaction.balance||0).toFixed(2),
            paymentNote:''
    	})
    }
    createRepair(){
		let self=this

        let due = new Date()
        due.setDate(due.getDate()+14)
        due.setHours(17,0,0,0)

		let customer=self.state.customerId
		let user=self.state.ut.id
		let transaction = self.state.transactionId
		//{type,title,description,instructions,customer,user,price}

		axios.post('/api/repairs',{due,customer,user,transaction,title:'NEW REPAIR'}).then( (data) => {
			let repair = data.data

			
			swal({
				title:"Success!",
				text:'Repair created successfully',
				type:"success",
				timer:1000
			}).then(value => {
          		self.fetch()
			});
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
    }
    returnRepair(repairObj,doReturn){
    	let self=this

		let message = "Are your sure you want to refund this repair?\n\n"+repairObj.title
		if(!doReturn){
			message = "Are your sure you want to undo the refund to this repair?\n\n"+repairObj.title
		}
	    swal(message, {
	    	icon: "warning",
	      buttons: {
	        nope: {
	          text: "Cancel",
	          value: "cancel"
	        },
	        sure: {
	          text: "I'm, Sure",
	          value: "delete",
	          className:'btn-danger'
	        }
	      }
	    }).then(value => {

	      switch (value) {
	        case "delete":
	      		
		    	let repair = JSON.parse(JSON.stringify(repairObj))
		    	repair.returned = doReturn
				axios.put('/api/repairs/'+repair._id,{repair}).then( (data) => {
					let repair = data.data

					
					swal({
						title:"Success!",
						text:'Repair returned successfully',
						type:"success",
						timer:1000
					}).then(value => {
		          		self.recalculate()
					});
			    }).catch((e) => {
			    	console.log('Repair return failed, Error ',e)
					// self.setState({errorMessage:e})
				});
	          break;
	        case "cancel":
	          break;
	        default:
	      }
	    });

    }
    returnSale(saleObj,doReturn){
    	let self=this

		let message = "Are your sure you want to return this sale?"
		if(!doReturn){
			message = "Are your sure you want to undo the refund to this sale?\n\n"
		}
	    swal(message, {
	    	icon: "warning",
	      buttons: {
	        nope: {
	          text: "Cancel",
	          value: "cancel"
	        },
	        sure: {
	          text: "I'm, Sure",
	          value: "delete",
	          className:'btn-danger'
	        }
	      }
	    }).then(value => {

	      switch (value) {
	        case "delete":
	      		
		    	let sale = JSON.parse(JSON.stringify(saleObj))
		    	sale.returned = doReturn
				axios.put('/api/sales/'+sale._id,{sale}).then( (data) => {
					let sale = data.data

					
					swal({
						title:"Success!",
						text:'Sale returned successfully',
						type:"success",
						timer:1000
					}).then(value => {
		          		self.recalculate()
					});
			    }).catch((e) => {
			    	console.log('Sale return failed, Error ',e)
					// self.setState({errorMessage:e})
				});
	          break;
	        case "cancel":
	          break;
	        default:
	      }
	    });

    }
    createSale(){
		let self=this
		let customer=self.state.customerId
		let user=self.state.ut.id
		let transaction = self.state.transactionId
		//{type,title,description,instructions,customer,user,price}

		axios.post('/api/sales',{customer,user,transaction,title:'NEW SALE'}).then( (data) => {
			let repair = data.data

			swal({
				title:"Success!",
				text:'Sale created successfully',
				type:"success",
				timer:1000
			}).then(value => {
          		self.fetch()
			});
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});

    }
    delete() {
    	let self=this
        swal("Delete this transaction?", {
            icon: "warning",
            buttons: {
                nope: {
                    text: "Cancel",
                    value: "nope"
                },
                sure: {
                    className: "btn-danger",
                    text: "I'm, Sure",
                    value: "sure"
                }
            }
        }).then(value => {

            switch (value) {
                case "sure":
                    console.log('Deleted')
                    axios.delete('/api/transactions/' + self.state.transactionId, {})
                        .then((data) => {
                            window.location = '/transactions'
                        }).catch((e) => {
                            console.log('Transaction delete failed, Error ', e)
                                // self.setState({errorMessage:e})
                        });
                    break;
                case "nope":
                    console.log('Didnt Delete (cancel)')
                    break;
                default:
                    console.log('Didnt Delete (dismissed)')
                        // swal("Got away safely!");
                    break;
            }
        });
    }
    recalculate(){
    	let self = this
        axios
            .post("/api/transactions/calculate",{_id:self.state.transactionId})//,taxRate:ELYRIA_TAX_RATE})
            .then(res => {
                throw("done")
            })
            .catch(error => {
                self.fetch()
            });

    }
    pay(){
    	let self = this
		let isRefund = (self.state.transaction.balance<0)?true:false

    	if(!self.state.paymentMethod || !self.state.paymentMethod.trim().length){
    		return swal("Error!", 'Please enter payment method.', "error");
    	}
    	if(!self.state.paymentAmount || isNaN(+self.state.paymentAmount) || +self.state.paymentAmount===0){
    		return swal("Error!", 'Please enter valid amount.', "error");	
    	}
    	if(!isRefund && (+self.state.paymentAmount)>(+self.state.maxPaymentAmount)){
    		return swal("Error!", 'Amount exceeds maximum of $'+self.state.maxPaymentAmount+'.', "error");	
    	}
    	if(isRefund && (+self.state.paymentAmount)<(+self.state.maxPaymentAmount)){
    		return swal("Error!", 'Amount exceeds maximum refund of $'+self.state.maxPaymentAmount+'.', "error");	
    	}
        if(!isRefund && self.state.paymentMethod==='storeCredit' && (+self.state.paymentAmount)>(+self.state.creditDetails.balance)){
            return swal("Error!", 'Amount exceeds storeCredit of $'+self.state.creditDetails.balance+'.', "error");  
        }

    	let paymentRequiresNote = false
		switch(self.state.paymentMethod){
			case 'coupon':
			case 'check':
			case 'synchrony':
			case 'visa':
			case 'mastercard':
			case 'amex':
			case 'discover':
				paymentRequiresNote = true
				break;
			default:
				paymentRequiresNote = false
				break;
		}

		// if(paymentRequiresNote && (!self.state.paymentNote || !self.state.paymentNote.trim().length ) ){
  //   		return swal("Error!", 'Please enter a note for this payment', "error");

		// }

        let customer = self.state.customerId
        let user = self.state.ut.id

		axios.post('/api/payments',{
			customer,
			amount:self.state.paymentAmount,
			type:self.state.paymentMethod,
			note:self.state.paymentNote,
			user,
			transaction:self.state.transactionId,
            shopId:localStorage.currentShop ||'EJR'

		}).then( (data) => {

			const payRefund = ((+(self.state.paymentAmount)||0)>0?'Payment':'Refund')

            const applyCredit = self.state.paymentMethod==='storeCredit'?true:false
            const applyCreditAmount = -1*(+(self.state.paymentAmount)||0)

			self.setState({
				showPaymentModal:false,
				paymentMethod:'',
				paymentAmount:'',
				maxPaymentAmount:'',
				paymentNote:''
			},()=>{

                if(applyCredit){

                    axios.post('/api/credits',{
                        customer,
                        user,
                        note:'Transaction '+ payRefund,
                        transaction:self.state.transactionId,
                        amount:applyCreditAmount
                    }).then( (data) => {
                        let credit = data.data

                        console.log({credit})
                        swal({
                          title:"Success!",
                          text:'Credit and ' +payRefund+ ' created successfully',
                          type:"success",
                          timer:1000
                        }).then(value => {
                              // window.location='/admin/credit/'+credit._id
                            self.recalculate()
                        });
                    }).catch((e) => {
                            console.log('Creation failed, Error ',e)
                            // self.setState({errorMessage:e})
                    });
                }
                else{

                    swal({
                      title:"Success!",
                      text:payRefund +' Applied',
                      type:"success",
                      timer:1000
                    }).then(value => {
                        self.recalculate()
                    });
                }

				// self.props.getCustomerById({id:this.state.customerId})
			})
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			self.setState({errorMessage:e})
		});
    }
    deletePayment(payment){
    	let self=this
        const payRefund = ((+(payment.amount)||0)>0?'Payment':'Refund')

        let deleteWarningMessage = 'Are your sure you want to delete this '+payRefund+'?  You should only do this if you entered a '+payRefund+' in error.'

        if(payment.type==='storeCredit'){
            deleteWarningMessage += '\n\nThis does NOT restore store credits.  You may need to manually add credits if you delete this '+payRefund+'.'
        }
	    swal(deleteWarningMessage, {
	    	icon: "warning",
	      buttons: {
	        nope: {
	          text: "Cancel",
	          value: "cancel"
	        },
	        sure: {
	          text: "I'm, Sure",
	          value: "delete",
	          className:'btn-danger'
	        }
	      }
	    }).then(value => {

	      switch (value) {
	        case "delete":
	      		console.log({delete:payment})

				axios.delete('/api/payments/'+payment._id, {})
				.then( (data) => {
			      console.log(`Payments deleted successfully`);

					self.setState({
						showPaymentHistoryModal:false
					},()=>{

			            swal({
			              title:"Success!",
			              text:'Payment Removed',
			              type:"success",
			              timer:1000
			            }).then(value => {
		  					self.recalculate()
			            });
						// self.props.getCustomerById({id:this.state.customerId})
					})
		      		// window.location='/repairs'
			      // console.log(data)
			      	// self.props.addPhotoToModel(data.data.filename)
					// self.setState({
					// 	selectedNote:data.data,
					// },self.filterResults)
			    }).catch((e) => {
			    	console.log('Payment delete failed, Error ',e)
					// self.setState({errorMessage:e})
				});
	          break;
	        case "cancel":
	          break;
	        default:
	      }
	    });

    }

    updatePaymentDate(payment,date,callback){
        let self=this
        let cb = callback || function(){}

        console.log({payment,date})

        payment.created = date.toISOString()
        payment.shopId = localStorage.currentShop || 'EJR'

        axios.put('/api/payments/' + payment._id, {
            payment
        }).then((data) => {
            console.log(`payment updated successfully`);
            cb()
        }).catch((e) => {
            console.log('update failed, Error ', e)
            swal("Error!", e.message, "error");
            cb()
            // self.setState({errorMessage:e})
        });
    }


  render() {
	let self=this


	let paymentModal = null


	if(self.state.showPaymentHistoryModal){

		paymentModal = (

						<Modal>
							<div className="modal">

								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showPaymentHistoryModal:false})
								}}></div>
								<div style={{position:'relative',background:'white',width:'70vw',height:'70vmin',zIndex:1000}}>
									
									  <div className="content-header">
									    <div className="container-fluid">
									      <div className="row mb-2">
									        <div className="col-sm-6">
									          <h1 className="m-0 text-dark">
									          Payment History
									          </h1>
									        </div>{/* /.col */}
									      </div>{/* /.row */}
									    </div>{/* /.container-fluid */}
									  </div>


									    <section className="content">
									      <div className="row">
									        <div className="col-12">

								            <div className="card-body" style={{maxHeight:'50vmin',overflowY:'scroll'}}>
								              <table id="example1" className="table table-bordered table-striped">
								                <thead>
								                <tr>
								                  <th>Type</th>
								                  <th>Date</th>
								                  <th>Note</th>
								                  <th>Amount</th>
								                </tr>
								                </thead>
								                <tbody>
								                {self.state.payments.map((p,px)=>{
								                	let elipsis = {
								                		maxWidth: '20vw',
								    					overflow: 'hidden',
								    					whiteSpace: 'nowrap',
								    					textOverflow: 'ellipsis'
								    				}
								    				let row_bg = ''
								    				// if(!t.balance>0){
								    				// 	row_bg = 'bg-pending'
								    				// 	if(tx%2==1){
								    				// 		row_bg = 'bg-pending-alt'
								    				// 	}
								    				// }

                                                    let pDateField = (
                                                        <div onClick={()=>{
                                                            // window.location='/transaction/'+t._id
                                                            let sDate = new Date(p.created.split('T')[0])
                                                            sDate.setHours(32,0,0,0)
                                                            console.log({sDate})
                                                            self.setState({
                                                                selectedPayment:p,
                                                                selectedDate:sDate
                                                            })
                                                        }}>
                                                            {(p.created||'').split('T')[0]}
                                                            &nbsp;
                                                            ({p.shopId})
                                                        </div>
                                                    )
                                                    if(self.state.selectedPayment===p){
                                                        pDateField = (
                                                            <React.Fragment>
                                                              <DatePicker
                                                                selected={new Date(self.state.selectedDate)}
                                                                onChange={(date) => {
                                                                    console.log({date})
                                                                    date.setHours(8,0,0,0)
                                                                    console.log({date})
                                                                    
                                                                    self.setState({selectedDate:date})
                                                                }}
                                                                dateFormat="yyyy-MM-dd"
                                                                customInput={<DatepickerInput />}
                                                              />
                                                            <div style={{textDecoration:'underline'}} onClick={(e)=>{
                                                                
                                                                self.updatePaymentDate(self.state.selectedPayment,self.state.selectedDate,()=>{
                                                                    self.setState({selectedDate:null,selectedPayment:null}) 
                                                                })
                                                                
                                                            }}>update</div>
                                                            </React.Fragment>
                                                        )
                                                    }

								                	return(

										                <tr className="payment-row" key={'p_'+p._id}>
										                  <td>
										                  	{p.type}
										                  	<div className="payment-cancel" style={{marginRight:'5px',float:'left'}} onClick={()=>{self.deletePayment(p)}}>
										                  		<i className="nav-icon fas fa-times-circle" />
										                  	</div>
										                  </td>
										                  <td style={{whiteSpace: 'nowrap'}}>{pDateField}</td>
										                  <td style={elipsis}>{p.note}</td>
										                  <td style={{textAlign: 'right'}}>${p.amount.toFixed(2)}</td>
										                </tr>
								                	)
								                })}
								                </tbody>
								              </table>
								            </div>
									        </div>
									      </div>
									      <div className="row" style={{marginTop:'3vmin'}}>
									        <div className="col-4"/>
									        <div className="col-4">
									          	<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.setState({showPaymentHistoryModal:false})}}>Close</button>
									        </div>
									      </div>
									    </section>


								</div>
							</div>
						</Modal>
		)
	}

	let addPaymentButton = null
	let paymentWord = 'Payment'
	let payWord = 'Pay'
	if((+(self.state.transaction.balance||0))>=0.01 || (+(self.state.transaction.balance||0))<=-0.01){
		paymentWord = ((self.state.transaction.balance||0)>0)?'Payment':'Refund'
		payWord = ((self.state.transaction.balance||0)>0)?'Pay':'Refund'
		addPaymentButton = (
	            <li style={{marginLeft:'10px'}}>           	
	          		<button type="button" className="btn btn-block btn-primary" onClick={()=>{self.createPayment()}}>+{paymentWord}</button>
	            </li>
		)
	}

	if(self.state.showPaymentModal){
		let paymentNotePlaceholder = paymentWord +' Note...'

		switch(self.state.paymentMethod){
			case 'coupon':
				paymentNotePlaceholder = 'REQUIRED: Enter coupon code/description'
				break;
			case 'check':
				paymentNotePlaceholder = 'REQUIRED: Enter check number'
				break;
			case 'synchrony':
				paymentNotePlaceholder = 'REQUIRED: Enter synchrony reference'
				break;
			case 'visa':
			case 'mastercard':
			case 'amex':
			case 'discover':
				paymentNotePlaceholder = 'REQUIRED: Enter card ref #'
				break;
			default:
				paymentNotePlaceholder = 'OPTIONAL: '+paymentWord+' Note...'
				break;
		}
		paymentModal = (

						<Modal>
							<div className="modal">

								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showPaymentModal:false})
								}}></div>
								<div style={{position:'relative',background:'white',width:'50vw',height:'60vmin',zIndex:1000}}>
									
									  <div className="content-header">
									    <div className="container-fluid">
									      <div className="row mb-2">
									        <div className="col-sm-6">
									          <h1 className="m-0 text-dark">
									          {paymentWord} Details
									          </h1>
									        </div>{/* /.col */}
									      </div>{/* /.row */}
									    </div>{/* /.container-fluid */}
									  </div>


									    <section className="content">
									      <div className="row">
									        <div className="col-1"/>
									        <div className="col-10">

							        			  <label>Method</label>

								                  <select 
								                  	className="form-control select2"
							                        onChange={(e)=>{

                                                        if(e.target.value==='storeCredit'){
                                                            self.setState({
                                                                paymentMethod:e.target.value,
                                                                paymentAmount:Math.min((self.state.creditDetails.balance||0),this.state.paymentAmount||0)
                                                            })    
                                                        }
                                                        else{
                                                            self.setState({paymentMethod:e.target.value})    
                                                        }
                                                        // self.setState({paymentMethod:e.target.value})
                                                    }}
							                        value={self.state.paymentMethod}>
														<option value="">Choose</option>
                                                        <option value="storeCredit">Store Credit ${(self.state.creditDetails.balance||0).toFixed(2)}</option>
														<option value="cash">cash</option>
														<option value="check">check</option>
														<option value="amex">american express</option>
														<option value="mastercard">mastercard</option>
														<option value="visa">visa</option>
														<option value="discover">discover</option>
														<option value="synchrony">synchrony</option>
														<option value="coupon">coupon</option>
														<option value="giftcard">giftcard</option>
								                  </select>


							        			  <label>Amount</label>

							                      <input
							                        onChange={(e)=>{
														let nVal =e.target.value.replace(/[^0-9\.\-]/gi,'')
														let isNeg = (nVal.slice(0,1)==='-')?true:false
														nVal = nVal.replace(/[^0-9\.]/gi,'')
														let decParts = nVal.split('.').slice(0,2)//.filter((sp)=>{return `${sp}`.trim().length && !isNaN(+sp)})
														nVal = `${isNeg?'-':''}${decParts.join('.')}`
														self.setState({paymentAmount:nVal})
													}}
							                        value={self.state.paymentAmount}               
							                        autoComplete="off"
							                        autoCorrect="off"
							                        autoCapitalize="off" 
							                        type="text" pattern="\d*"
							                        className="form-control"
							                        id="title"
							                        placeholder="Enter Payment Amount"
							                        onBlur={(e)=>{
							                        	if(isNaN(+this.state.paymentAmount)){
							                        		self.setState({paymentAmount:'0.00'})
							                        	}
							                        	else{
							                        		self.setState({paymentAmount:(+(e.target.value.replace(/[^0-9\.\-]/gi,''))).toFixed(2) })	
							                        	}
							                        }}
							                      />

							        			  <label>Notes</label>

							                      <textarea 
							                      	className="form-control" 
							                      	rows="4" 
							                        onChange={(e)=>{self.setState({paymentNote:e.target.value})}}
							                        value={this.state.paymentNote}
							                      	placeholder={paymentNotePlaceholder}/>
									        </div>
									      </div>
									      <div className="row" style={{marginTop:'3vmin'}}>
									        <div className="col-1"/>
									        <div className="col-3">
									          	<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.setState({showPaymentModal:false})}}>Cancel</button>
									        </div>
									        <div className="col-4"/>
									        <div className="col-3">
									          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.pay()}}>{payWord}</button>
									        </div>
									        <div className="col-1"/>
									      </div>
									    </section>


								</div>
							</div>
						</Modal>
		)
	}

	let printButton = (

              <li style={{marginLeft: '10px'}}>          
			  {/* <a href={axios.defaults.baseURL + '/api/print/transaction/'+ this.state.transactionId} target='_blank' className="btn btn-block btn-primary">Print</a> */}
                <a href={'/api/print/transaction/'+ this.state.transactionId} target='_blank' className="btn btn-block btn-primary">Print</a>
              </li>
	)
	let addRepairButton = null
	let addSaleButton = null

	if(!self.state.transaction.closed || !self.state.payments || !self.state.payments.length || self.state.transaction.balance<=-0.01){
		addRepairButton = (
	            <li style={{marginLeft:'10px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createRepair()}}>+Repair</button>
	            </li>
		)

		addSaleButton = (
	            <li style={{marginLeft:'10px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createSale()}}>+Sale</button>
	            </li>
		)
	}
		let deleteButton = null
		if((!self.state.sales || !self.state.sales.length) && (!self.state.repairs || !self.state.repairs.length) && (!self.state.payments || !self.state.payments.length)){
			deleteButton = (

	            <li style={{marginLeft: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-danger" onClick={()=>{
    					self.delete()
    				}}>Delete</button>
	            </li>

			)
		}

		let returnListing=null
  		let paymentCount=self.state.payments.filter((p)=>{return p.amount>=0.01}).length
  		let returnCount=self.state.payments.filter((p)=>{return p.amount<=-0.01}).length
  		if(returnCount){
  			returnListing=(
  				<React.Fragment>
  					/<span className="badge badge-warning left" style={{marginRight:'1px'}}>{returnCount}</span>Refund{returnCount===1?'':'s'}
  				</React.Fragment>
  			)
  		}


	if(!self.state.allFetched){
      	addPaymentButton = null
        addRepairButton = null
        addSaleButton = null
        deleteButton = null
        printButton = null
	}

    let THE_TAX_RATE = self.state.transaction.taxRate || ELYRIA_TAX_RATE

    let taxRemainder = (self.state.transaction.taxTotal||0)

    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-4">
	          <h1 className="m-0 text-dark">
	          Transaction:
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-8">
	          <ol className="breadcrumb float-sm-right">
	          	{addPaymentButton}
	            {addRepairButton}
	            {addSaleButton}
	            {deleteButton}
	            {printButton}
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">

        	<div className="col-12">
		        <div className="col-6" style={{display:'inline-block'}}>
		        	<b style={{marginLeft:'1vmin'}}>{self.state.transaction.refId}</b><br/>
                    
		        </div>
		        <div className="col-6" style={{display:'inline-block', textAlign:'right'}}>
		        	<input name="closed_cb" id="closed_cb" type="checkbox"  style={{marginRight:'1vmin'}} checked={(self.state.transaction.closed ||false)} onChange={(e)=>{
		        		self.updateField('closed',e.target.checked)
		        	}}/>
		          	<label htmlFor="closed_cb" style={{marginRight:'1vmin'}}>Closed</label>
		        	<input name="lay_cb" id="lay_cb" type="checkbox"  style={{marginRight:'1vmin'}} checked={(self.state.transaction.layaway ||false)} onChange={(e)=>{
		        		self.updateField('layaway',e.target.checked)
		        	}}/>
		          	<label htmlFor="lay_cb" style={{marginRight:'1vmin'}}>Layaway</label>
		        </div>
	        </div>

            <div className="card-body">
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  <th><img src={self.state.transaction.shopId + '-logo-gray.png'} alt={self.state.transaction.shopId} className="brand-image img-circle elevation-3" style={{opacity:0.8,width:'100px',background:'#343a40'}} /></th>
                  <th>Description</th>
                  {
                  // <th>Price</th>
              	  }
                  <th style={{textAlign:'right'}}>Subtotal</th>
                </tr>
                </thead>
                <tbody>
                {self.state.repairs.map((r,rx)=>{
                	let elipsis = {
                		// width:'50vw',
                		// maxWidth: '20vw',
    					overflow: 'hidden',
    					whiteSpace: 'nowrap',
    					textOverflow: 'ellipsis'
    				}
    				let rowTax = null
    				let rowTaxAmount = 0
    				let rowPrice = Math.round((r.price||0)*100)/100
    				let rowTotalAmount = Math.round((r.price||0)*100)/100
                    let isLastItem = ((self.state.sales.length===0 && (self.state.repairs.length-1 === rx))?true:false)
    				if(r.taxable){
						if(r.taxInPrice){
							rowPrice = Math.round((r.price||0)/(1+(THE_TAX_RATE||0))*100)/100
							rowTaxAmount = Math.round(((r.price||0)-rowPrice)*100)/100

                            taxRemainder-=rowTaxAmount
						}
						else{
	    					rowTaxAmount = Math.round((r.price||0)*THE_TAX_RATE*100)/100
	    					rowTotalAmount = Math.round(((r.price||0)+rowTaxAmount)*100)/100

                            taxRemainder-=rowTaxAmount
						}
                        if(isLastItem){
                            rowTaxAmount += taxRemainder
                            taxRemainder=0
                        }

    					rowTax = (
    						<React.Fragment>
    							<br/>
    							tax{r.taxInPrice?' (included)':''}: ${(rowTaxAmount).toFixed(2)}
    						</React.Fragment>
    					)
    				}


    				let repairReturnButton = null
    				if(self.state.payments && self.state.payments.length){
    					repairReturnButton = (
							<div className="row">
								<div className="col-4"/>
								<div className="col-8" style={{textDecoration:'none'}}>
								  	<button type="button" className={["btn btn-block lineItemReturnButton", r.returned?"btn-warning":"btn-danger"].join(' ')} onClick={()=>{
										self.returnRepair(r,!r.returned)
									}}>{r.returned?'Undo Refund':'Refund'}</button>
								</div>
							</div>
    					)
    				}
    				let photoElement = <img style={{width:'80px'}} src={'/diamond-pink.png'} />
    				let photo
    				if(self.state.photos && self.state.photos.length){

    						console.log({p:self.state.photos[0].refId,s:r._id})
    					photo =	self.state.photos.filter((p)=>{return p.refId && p.refId == r._id})[0]	
    				}
    				if(photo){
    					// photoElement = <img style={{width:'100px'}} src={axios.defaults.baseURL + "/api/photos/"+photo.type+'/'+photo.fileName} />
    					photoElement = <img style={{width:'100px'}} src={"/api/photos/"+photo.type+'/'+photo.fileName} />
    				}

                	return(

		                <tr className={"lineItemRow" + (r.returned?' lineItemReturned':'')} key={'r_'+r._id}>
			                <td style={{width:'80px'}}>
			                	{photoElement}	
		                	</td>
		                  <td style={elipsis} onClick={()=>{
		                	window.location='/repair/'+r._id
		                }}>
		                  	<b>{[r.status,(r.type||''),'Repair:',r.title].filter((vv)=>{return vv && vv.trim().length}).join(' ').toUpperCase()}</b><br />
		                  	{r.description}<br />
		                  	<b>Instructions</b><br />
		                  	{r.instructions}<br />
                            <small>{r.notes?'NOTES:' +r.notes:''}</small>
		                  </td>
		                  <td style={{textAlign: 'right',verticalAlign: 'bottom'}}>

		                  	{repairReturnButton}
		                  	${rowPrice.toFixed(2)}
		                  	{rowTax}
		                  	</td>
		                  {
		                  // <td style={{textAlign: 'right'}}>${rowTotalAmount.toFixed(2)}</td>
		              	  }
		                </tr>
                	)
                })}
                {self.state.sales.map((s,sx)=>{
                	let elipsis = {
                		// width:'50vw',
                		// maxWidth: '20vw',
    					overflow: 'hidden',
    					whiteSpace: 'nowrap',
    					textOverflow: 'ellipsis',
    					maxWidth: '40vw'
    				}
    				let rowTax = null
    				let rowTaxAmount = 0
    				let rowPrice = (s.price||0)
    				let rowTotalAmount = (s.price||0)
                    let isLastItem = ((self.state.sales.length-1 === sx)?true:false)

    				if(s.taxable){

						if(s.taxInPrice){
							rowPrice = Math.round((s.price||0)/(1+(THE_TAX_RATE||0))*100)/100
							rowTaxAmount = (s.price||0)-rowPrice

                            taxRemainder-=rowTaxAmount
						}
						else{
	    					rowTaxAmount = Math.round((s.price||0)*THE_TAX_RATE*100)/100
	    					rowTotalAmount = (s.price||0)+rowTaxAmount
                            
                            taxRemainder-=rowTaxAmount
						}
                        if(isLastItem){
                            rowTaxAmount += taxRemainder
                            taxRemainder=0
                        }

    					rowTax = (
    						<React.Fragment>
    							<br/>
    							tax{s.taxInPrice?' (included)':''}: ${(rowTaxAmount).toFixed(2)}
    						</React.Fragment>
    					)
    				}

    				let saleReturnButton = null
    				if(self.state.payments && self.state.payments.length){
    					saleReturnButton = (
							<div className="row">
							    <div className="col-4"/>
							    <div className="col-8">
							      	<button type="button" className={["btn btn-block lineItemReturnButton", s.returned?"btn-warning":"btn-danger"].join(' ')} onClick={()=>{
										self.returnSale(s,!s.returned)
									}}>{s.returned?'Undo Return':'Return'}</button>
								</div>
							</div>
    					)
    				}
    				let photoElement = <img style={{width:'80px'}} src={'/diamond-pink.png'} />
    				let photo
    				if(self.state.photos && self.state.photos.length){
    						console.log('')
    						console.log('')
    						console.log('')
    						console.log({p:self.state.photos[0].refId,s:s._id})
    					photo =	self.state.photos.filter((p)=>{
    						return p.refId && p.refId == s._id})[0]	
    				}
    				if(photo){
    					// photoElement = <img style={{width:'100px'}} src={axios.defaults.baseURL + "/api/photos/"+photo.type+'/'+photo.fileName} />
    					photoElement = <img style={{width:'100px'}} src={"/api/photos/"+photo.type+'/'+photo.fileName} />
    				}
                	return(

		                <tr className={"lineItemRow" + (s.returned?' lineItemReturned':'')} key={'s_'+s._id}>
			                <td style={{width:'80px'}}>
			                	{photoElement}	
		                	</td>
		                  <td style={elipsis} onClick={()=>{
			                	window.location='/sale/'+s._id
			                }}>
		                  	<b>{(s.type||'').toUpperCase()}</b><br />
		                  	<b>{['Sale:',s.sku,s.title].join(' ')}</b><br />
		                  	{s.description}
		                  </td>
		                  <td style={{textAlign: 'right'}}>

						    {saleReturnButton}
		                  	${rowPrice.toFixed(2)}
		                  	{rowTax}
		                  </td>
		                  {
		                  // <td style={{textAlign: 'right'}}>${rowTotalAmount.toFixed(2)}</td>
		              	  }
		                </tr>
                	)
                })}
	                <tr key={'r_subtotal'}>
	                  <td colSpan={2} style={{textAlign: 'right'}}>
	                  	Subtotal:<br/>
	                  	Sales Tax ({(THE_TAX_RATE*100).toFixed(2)}%):<br/>
	                  	<b>Total:</b><br/>
	                  	<a style={{cursor:'pointer'}}onClick={()=>{
	                  		self.setState({showPaymentHistoryModal:true})
	                  	}}>
		                  	<span className="badge badge-info left" style={{marginRight:'1px'}}>{paymentCount}</span>
		                  	Payment{(paymentCount===1?'':'s')}{returnListing}:
		                </a><br/>
	                  	<b>Balance {((self.state.transaction.balance||0)<0?'To Customer':'Due')}:</b><br/>
	                  </td>
	                  <td style={{textAlign: 'right'}}>
	                  	${(self.state.transaction.lineItemTotal||0).toFixed(2)}<br/>
	                  	${(self.state.transaction.taxTotal||0).toFixed(2)}<br/>
	                  	<b>${((self.state.transaction.lineItemTotal||0)+(self.state.transaction.taxTotal||0)).toFixed(2)}</b><br/>
	                  	${(self.state.transaction.paymentTotal||0).toFixed(2)}<br/>
	                  	<b>${(self.state.transaction.balance||0).toFixed(2)}</b>
	                  </td>
	                </tr>
                </tbody>
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  	}
    </section>

    {paymentModal}
	</div>

      );
  }
}

export default Transactions;
