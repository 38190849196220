import React, {Component}
from 'react';
import swal from "sweetalert";
import axios from "axios";
import adminAxios from "../../utils/adminAxios";


import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";


const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

class AdminReports extends Component {

    constructor(props) {
        super(props)

        this.state = {
	  		userId:this.props.userId,
	      	ut:this.parseJwt(),
	      	dateRangeType:'month',
	      	taxReport:{}, 
	      	selectedTransaction:null, 
	      	selectedDate:null,
	      	showOnlyClosed:true,
	      	selectedShopId:'ejr'
  		}
  	}

  	componentDidMount(){

    	this.calculateDateRange('month')
    	// this.fetchReports()
  	}

    parseJwt() {
        let token = localStorage.getItem("jwtToken");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
    }

    calculateDateRange(type,offset){
    	let self=this;

    	let now = new Date()
    	let start = new Date()
    	let end = new Date()

    	switch(type){
    		case 'year':
    			start = new Date(now.getFullYear()+'-01-01T06:00:00.000Z')
    			end = new Date(now.getFullYear()+'-12-31T11:59:59.999Z')
    			break;
    		case 'quarter':
    			start = self.getQuarterStart()
    			end = new Date()
    			break;
    		case 'month':
    			start = new Date()
    			start.setDate(1)
    			end = new Date()
    			end.setDate(end.getDate()+1)
    			break;
    		case 'week':
    			start = new Date()
    			start.setDate(start.getDate()-start.getDay())
    			end = new Date()
    			break;
    			

    	}
    	start.setHours(6,0,0,0)
    	console.log({dateRange:{start,end}})
    	self.setState({
    		dateRangeType:type,
    		dateRange:{start,end}
    	},()=>{
    		self.fetchReports()
    	})

    }

    getQuarterStart(){
	    let newDt = new Date()
	    let dd = Math.floor((newDt.getMonth())/3)*3

	    newDt.setMonth(dd)
	    newDt.setDate(1)
	    newDt.setHours(12,0,0,0)
	    return newDt
	}


  	// fetchCustomers(){
  	// 	let self=this
   //      axios
   //          .get("/api/report/customers")
   //          .then(res => {
   //              // if (res.data && res.data.length) {
   //              console.log({
   //                  users: res
   //              });
   //              // }
   //          })
   //          .catch(error => {
   //              console.log(error);
   //          });
   //  }

  	fetchReports(){
  		let self=this

  		let start = self.state.dateRange.start.toISOString().split('T')[0]
  		let end = self.state.dateRange.end.toISOString().split('T')[0]
  		let shopId = ''
  		if(['ejr','willow','endless'].indexOf(self.state.selectedShopId.toLowerCase())>=0){
  			shopId = self.state.selectedShopId.toLowerCase()
  		}


        axios
            .get("/api/report/tax/" + start + '/' + end + '/' + shopId)
            .then(res => {
                // if (res.data && res.data.length) {
                console.log({
                    report: res.data
                });
                self.setState({taxReport:res.data})
                // }
            })
            .catch(error => {
                console.log(error);
            });
  	}


  	closeTransaction(transaction,callback){
  		let self=this
		let cb = callback || function(){}
  		transaction.ref.closed=true
  		self.updateTransactionCloseDate(transaction.ref,new Date(),cb)
  	}

  	updateTransactionCloseDate(transaction,date,callback){
  		let self=this
		let cb = callback || function(){}

		console.log({transaction,date})

		transaction.closeDate = date.toISOString()

        axios.put('/api/transactions/' + transaction._id, {
            transaction
        }).then((data) => {
            console.log(`transaction updated successfully`);
            cb()
        }).catch((e) => {
            console.log('update failed, Error ', e)
            swal("Error!", e.message, "error");
            cb()
            // self.setState({errorMessage:e})
        });
  	}



    render(){
    	let self=this;

    	let dateRangeFields = null
    	if(self.state.dateRange){
    		dateRangeFields = (

				<div style={{display:'block'}}>
					<div style={{display:'inline-block'}}>
						<span style={{marginLeft:'30px',marginRight:'10px'}}>Shop:</span>
					</div>
					<div style={{display:'inline-block'}}>
						<select value={self.state.selectedShopId} onChange={(e)=>{
							self.setState({selectedShopId:e.target.value},()=>{
								self.fetchReports()
							})
						}}>
							<option value="ejr">Elyria Jewelers</option>
							<option value="willow">Willow Jewelers</option>
							<option value="endless">Endless & Forever</option>
						</select>
					</div>
					<div style={{display:'inline-block'}}>
						<span style={{marginLeft:'30px',marginRight:'10px'}}>From:</span>
					</div>
					<div style={{display:'inline-block'}}>
						
	                  <DatePicker
	        			selected={self.state.dateRange.start}
	                    onChange={(date) => {
	                    	let dateRange = self.state.dateRange
	                    	dateRange.start=date
	                    	self.setState({dateRange,dateRangeType:'custom'},()=>{
	    						self.fetchReports()
	                    	})
	                    }}
	                    dateFormat="yyyy-MM-dd"
	                    customInput={<DatepickerInput />}
	                  />
					</div>
					<div style={{display:'inline-block'}}>
						<span style={{marginLeft:'30px',marginRight:'10px'}}>To:</span>
					</div>
					<div style={{display:'inline-block'}}>
						
	                  <DatePicker
	        			selected={self.state.dateRange.end}
	                    onChange={(date) => {
	                    	let dateRange = self.state.dateRange
	                    	dateRange.end=date
	                    	self.setState({dateRange,dateRangeType:'custom'},()=>{
	    						self.fetchReports()
	                    	})
	                    }}
	                    dateFormat="yyyy-MM-dd"
	                    customInput={<DatepickerInput />}
	                  />
					</div>
					<div style={{display:'inline-block',marginLeft:'30px'}}>
						
	                  <input id="showOnlyClosed" type="checkbox" checked={self.state.showOnlyClosed} onChange={(e)=>{
	                  	self.setState({showOnlyClosed:e.target.checked})
	                  }} /><label htmlFor="">Closed</label>
					</div>
					


                  {
                  	// <input type="text" disabled={true} value={self.state.dateRange.start.toISOString().split('T')[0]} />
					// <span style={{marginLeft:'30px',marginRight:'10px'}}>To:</span>
					// <input type="text" disabled={true} value={self.state.dateRange.end.toISOString().split('T')[0]} />
                  }
					
					
				</div>
    		)
    	}

    	let txRows = []

		let tLine=0
		let tTax=0
		let tTot=0
		let tPay=0
		let tRet=0
		let tBal=0

		let currentTransactions = self.state.taxReport.trans
    	if(currentTransactions && currentTransactions.length){


    		//filter closed or all
			if(self.state.showOnlyClosed){
				currentTransactions = currentTransactions.filter((ctx)=>{return ctx.ref.closed})
			}
    		// let txKeys = Object.keys(self.state.taxReport.transactions)


    		currentTransactions.forEach((tx,tk)=>{
    			// let tx = self.state.taxReport.transactions[tk]

    			let txLineItemTotal = 0
    			let txTaxTotal = 0
    			let taxRate = 0
    			if(tx.ref.taxRate){
    				taxRate = tx.ref.taxRate
    			}
    			if(tx.sales && tx.sales.length){
    				tx.sales.forEach((s)=>{
		    			// if(!s.returned){
							if(s.taxable){
								if(s.taxInPrice){
									let price = Math.round((s.price||0)/(1+(taxRate||0))*100)/100
									let tax = Math.round(((s.price||0)-price)*100)/100

									txLineItemTotal += price
									txTaxTotal += tax	
								}
								else{
									txLineItemTotal += Math.round((s.price||0)*100)/100
									txTaxTotal += Math.round((s.price||0)*(taxRate||0)*100)/100	
								}
							}
							else{
									txLineItemTotal +=  Math.round((s.price||0)*100)/100
							}
						// }
					})
    			}
    			if(tx.repairs && tx.repairs.length){
    				tx.repairs.forEach((r)=>{
		    			// if(!s.returned){
							if(r.taxable){
								if(r.taxInPrice){
									let price = Math.round((r.price||0)/(1+(taxRate||0))*100)/100
									let tax = Math.round(((r.price||0)-price)*100)/100

									txLineItemTotal += price
									txTaxTotal += tax	
								}
								else{
									txLineItemTotal += Math.round((r.price||0)*100)/100
									txTaxTotal += Math.round((r.price||0)*(taxRate||0)*100)/100	
								}
							}
							else{
									txLineItemTotal +=  Math.round((r.price||0)*100)/100
							}
						// }
					})
    			}

    			let txPaymentTotal = 0
    			if(tx.payments && tx.payments.length){
    				tx.payments.forEach((r,rx)=>{
    					txPaymentTotal += r.amount
    				})
    			}

    			let txReturnTotal = 0
    			if(tx.returns && tx.returns.length){
    				tx.returns.forEach((r,rx)=>{
    					txReturnTotal += r.amount
    				})
    				txLineItemTotal = txReturnTotal/(1+taxRate)
    				txTaxTotal = txReturnTotal-txLineItemTotal
    			}

				tLine+=txLineItemTotal
				tTax+=txTaxTotal
				tTot+=(txLineItemTotal+txTaxTotal)
				tPay+=txPaymentTotal
				tRet+= txReturnTotal
				tBal+=tx.ref.balance


				let txTot = txLineItemTotal+txTaxTotal
				let txClassName= txTot>0?'reportline-pos':'reportline-neg'

				//tx.ref.refId
				let closeDate = '-'
				if(tx.ref.closed && tx.ref.closeDate){

					closeDate = <div onClick={(e)=>{
						if(tx.returns.length){
							return
						}
						let sDate = new Date(tx.ref.closeDate)
						sDate.setHours(8,0,0,0)
    					self.setState({
    						selectedDate:sDate,
    						selectedTransaction:tx
    					})
    				}}>{tx.ref.closeDate.split('T')[0]}</div>
				}
				else if(tx.ref.closed && !tx.returns.length){

					closeDate = <div style={{fontSize:'.7em'}} onClick={(e)=>{
						if(tx.returns.length){
							return
						}
						let sDate = new Date()
						sDate.setHours(8,0,0,0)
    					self.setState({
    						selectedDate:sDate,
    						selectedTransaction:tx
    					})
    				}}>click to add date</div>
				}
				else if(!tx.ref.closed && !tx.returns.length){

					closeDate = <div style={{fontSize:'.7em'}} onClick={(e)=>{
						self.closeTransaction(tx,()=>{
							self.setState({selectedDate:null,selectedTransaction:null})
						})
    				}}>click to close</div>
				}
				if(self.state.selectedTransaction && self.state.selectedTransaction.ref._id===tx.ref._id && !tx.returns.length){
					closeDate = (
						<React.Fragment>
		                  <DatePicker
		        			selected={new Date(self.state.selectedDate)}
		                    onChange={(date) => {
		                    	date.setHours(8,0,0,0)
		                    	
		                    	self.setState({selectedDate:date})
		                    }}
		                    dateFormat="yyyy-MM-dd"
		                    customInput={<DatepickerInput />}
		                  />
		                <div style={{textDecoration:'underline'}} onClick={(e)=>{
		                	
		                	self.updateTransactionCloseDate(self.state.selectedTransaction.ref,self.state.selectedDate,()=>{
		                		self.setState({selectedDate:null,selectedTransaction:null})	
		                	})
		                    
		                }}>update</div>
		                </React.Fragment>
					)
				}

    			let cust = tx.ref.customer
				if(tx.customer && tx.customer.firstName){
					cust = [tx.customer.lastName,tx.customer.firstName].join(', ')
				}
				// (tx.customer && tx.customer.length)?tx.customer:[tx.customer.lastName,tx.customer.firstName].join(', ')

    			let txRow = (
    				<tr className={txClassName} key={'r_'+tk}>
		          				<td style={{paddingLeft:'20px'}}>{tx.ref.created.split('T')[0]}</td>
		          				<td style={{paddingLeft:'20px'}}>{tx.ref.shopId}</td>
		          				<td style={{paddingLeft:'20px'}}>
		          					<div onClick={(e)=>{
										let sDate = new Date(tx.lastPayment.split('T')[0])
										sDate.setHours(32,0,0,0)
										console.log({sDate})
				    					self.setState({
				    						selectedDate:sDate
				    					})
				    				}}>
		          						{tx.lastPayment.split('T')[0]}
		          					</div>
		          				</td>
		          				<td style={{paddingLeft:'20px'}}>{closeDate}</td>
		          				<td style={{paddingLeft:'20px'}}>{cust}</td>
		          				<td style={{textAlign:'right'}}>{txLineItemTotal.toFixed(2)}</td>
		          				<td style={{textAlign:'right'}}>{txTaxTotal.toFixed(2)}</td>
		          				<td style={{textAlign:'right'}}>{(txLineItemTotal+txTaxTotal).toFixed(2)}</td>
		          				<td style={{textAlign:'right'}}>{txPaymentTotal.toFixed(2)}</td>
		          				<td style={{textAlign:'right'}}>{txReturnTotal.toFixed(2)}</td>
		          				<td style={{textAlign:'right'}}>{tx.ref.balance.toFixed(2)}</td>
    					
    				</tr>
    			)
    			txRows.push(txRow)
    		})
    	}

    	return(
		<div className="content-wrapper">
		  {/* Content Header (Page header) */}
		  <div className="content-header">
		    <div className="container-fluid">
		      <div className="row mb-2">
		        <div className="col-sm-6">
		          <h1 className="m-0 text-dark">
		          Tax Reports
		          </h1>
		        </div>{/* /.col */}

		      </div>{/* /.row */}
		    </div>{/* /.container-fluid */}
		  </div>
		  {/* /.content-header */}
		  {/* Main content */}



		    <section className="content">


		      <div className="card card-solid">
		        <div className="card-body pb-0">
		          <div className={""
		          	// "row d-flex align-items-stretch"
		          }>
		          		<div style={{display:'block'}}>
							<a href="#ytd" className={"btn "+(self.state.dateRangeType==='year'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('year')
							}}>Year to Date</a>
							<a href="#qtd" className={"btn "+(self.state.dateRangeType==='quarter'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('quarter')
							}}>Quarter to Date</a>
							<a href="#mtd" className={"btn "+(self.state.dateRangeType==='month'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('month')
							}}>Month to Date</a>
							<a href="#week" className={"btn "+(self.state.dateRangeType==='week'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('week')
							}}>This Week</a>
						</div>
						{dateRangeFields}
		          </div>
		          <div>
		          	<table>
		          		<thead>
		          			<tr>
		          				<th style={{paddingLeft:'20px'}}>TX Date</th>
		          				<th style={{paddingLeft:'20px'}}>Shop</th>
		          				<th style={{paddingLeft:'20px'}}>Last Payment</th>
		          				<th style={{paddingLeft:'20px'}}>Closed</th>
		          				<th style={{paddingLeft:'20px'}}>Customer</th>
		          				<th style={{textAlign:'right'}}>Subtotal</th>
		          				<th style={{textAlign:'right'}}>Taxes</th>
		          				<th style={{textAlign:'right'}}>Total</th>
		          				<th style={{textAlign:'right'}}>Payments</th>
		          				<th style={{textAlign:'right'}}>Returns</th>
		          				<th style={{paddingLeft:'20px',textAlign:'right'}}>Balance</th>
		          			</tr>
		          		</thead>
		          		<tbody>
		          			{txRows}
		          		</tbody>
		          		<tfoot>
		          			<tr>
		          				<th style={{paddingLeft:'20px'}}>TX Date</th>
		          				<th style={{paddingLeft:'20px'}}>Shop</th>
		          				<th style={{paddingLeft:'20px'}}>Last Payment</th>
		          				<th style={{paddingLeft:'20px'}}>Closed</th>
		          				<th style={{paddingLeft:'20px'}}>Customer</th>
		          				<th style={{textAlign:'right'}}>Subtotal</th>
		          				<th style={{textAlign:'right'}}>Taxes</th>
		          				<th style={{textAlign:'right'}}>Total</th>
		          				<th style={{textAlign:'right'}}>Payments</th>
		          				<th style={{textAlign:'right'}}>Returns</th>
		          				<th style={{paddingLeft:'20px',textAlign:'right'}}>Balance</th>
		          			</tr>
    						<tr key={'r_totals'}>
		          				<td colSpan="5">Totals</td>
		          				<td style={{paddingLeft:'20px',fontWeight:'bold',textAlign:'right'}}>{tLine.toFixed(2)}</td>
		          				<td style={{paddingLeft:'20px',fontWeight:'bold',textAlign:'right'}}>{tTax.toFixed(2)}</td>
		          				<td style={{paddingLeft:'20px',fontWeight:'bold',textAlign:'right'}}>{tTot.toFixed(2)}</td>
		          				<td style={{paddingLeft:'20px',fontWeight:'bold',textAlign:'right'}}>{tPay.toFixed(2)}</td>
		          				<td style={{paddingLeft:'20px',fontWeight:'bold',textAlign:'right'}}>{tRet.toFixed(2)}</td>
		          				<td style={{paddingLeft:'20px',fontWeight:'bold',textAlign:'right'}}>{tBal.toFixed(2)}</td>
    					
    						</tr>
		          		</tfoot>
		          	</table>
		          </div>
		        </div>
		      </div>

		    </section>
		</div>
    	)
    }

}

export default AdminReports;